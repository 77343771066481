<template>
  <div>
    <div class="view-body">
      <div class="gq-project-top-box">
        <div class="gq-project-top-background"></div>
        <img
          src="@/assets/imgs/project-top.jpg"
          class="project-top-img"
          alt=""
        />
        <div class="gq-project-top-txt" v-show="activeIndex == 0">
          CHOOSE YOUR <br />
          QUALITY PROJECT!
        </div>
        <div class="gq-project-state-box" v-show="activeIndex != 0">
          <div class="gq-project-state-title">
            {{ titlelist[activeIndex].name }}
          </div>
          <ul class="gq-project-state-ul">
            <li
              v-for="(item, i) in titlelist[activeIndex].contentList"
              :key="i"
            >
              <span class="gq-project-fk"></span>
              <span class="gq-project-span-txt">{{ item }}</span>
            </li>
          </ul>
        </div>

        <div
          class="gq-project-top-bottom-jiantou"
          @click="tonav"
          v-show="activeIndex == 0"
        >
          <img src="@/assets/logos/right.png" alt="" />
        </div>
        <div
          class="gq-project-top-bottom-state-jiantou"
          @click="tonav"
          v-show="activeIndex != 0"
        >
          <img src="@/assets/logos/swiperjiantou.png" alt="" />
        </div>
        <ul class="gq-project-nav-ul">
          <li
            v-for="(item, i) in proList"
            :key="i"
            @click="pcstateChange(i)"
            :class="activeIndex == i ? 'li-active' : ''"
            v-text="stateName(item)"
          >

          </li>
        </ul>
      </div>
      <div>
        <div class="gq-project-top-phone-box">
          <!-- <gqswiper /> -->
          <div class="news-top-img-box">
            <img src="@/assets/imgs/swiper.jpg" alt="" />
          </div>
          <ul class="gq-project-phone-ul">
            <li
              v-for="(item, i) in proList"
              :key="i"
              :class="activeIndex == i + 1 ? 'li-active' : ''"
              @click="stateChange(i + 1)"
              v-text="stateName(item)"
            ></li>
          </ul>
        </div>
        <div class="gq-project-list" v-show="activeIndex == 0">
          <div v-for="(item, i) in proList" :key="i + 2" v-show="i != 0">
            <projectriht
              ref="refProjectriht"
              @changeDetail="trueShow"
              :projectData="item"
              v-if="i % 2 != 0"
              :projectTitle="titlelist"
            />
            <projectleft
              ref="refProjectleft"
              @changeDetail="trueShow"
              :projectData="item"
              :projectTitle="titlelist"
              v-else
            />
          </div>
        </div>
        <div class="gq-project-list" v-show="activeIndex != 0">
          <div class="gq-project-list-box">
            <div
              class="gq-project-list-item"
              v-for="(item, i) in pcList"
              :key="i"
            >
              <img :src="item.projectChart" alt="" @click="trueShow(item)" />
              <div class="gq-project-list-item-txt" @click="trueShow(item)">
                {{ item.projectName }}
              </div>
            </div>
          </div>
          <div class="gq-project-page-box">
            <el-pagination
              background
              layout="prev, pager, next"
              @prev-click="prev"
              @next-click="next"
              @current-change="current"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="gq-project-phone-list">
          <div class="gq-news-top" @click="top">
            <div class="gq-news-top-img-box">
              <img src="@/assets/logos/top.png" alt="" />
              TOP
            </div>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="No more"
            @load="onLoad"
          >
            <div
              class="gq-project-phone-item"
              v-for="(item, i) in list"
              :key="i"
            >
              <img :src="item.projectChart" alt="" />
              <div class="gq-project-phone-item-title" @click="toDetail(item)">
                {{ item.projectName }}
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>

    <div class="gq-project-bottom">
      <gq-introduction />
    </div>
    <detail v-show="detailShow" @changeShow="falseShow" ref="refdetail" />
  </div>
</template>
<script>
import gqswiper from "../../components/gq-swiper.vue";
import projectriht from "./components/projectright.vue";
import projectleft from "./components/projectleft.vue";
import gqIntroduction from "@/components/gq-introduction.vue";
import detail from "@/components/projectDetail.vue";
import { List } from "vant";
import { projectProjectByCountry, projectList } from "@/api";
export default {
  components: {
    projectriht,
    gqIntroduction,
    projectleft,
    gqswiper,
    detail,
    vanList: List,
  },

  data() {
    return {
      activeIndex: 0,
      list: [],
      pcList: [],
      loading: false,
      finished: false,
      detailShow: false,
      navList: ["Whole", "Canada", "U.S.A", "UK&EU", "Australia", "NZL&Asia"],
      proList: [{ country: 0 }],
      page: 1,
      total: 0,
      titlelist: {
        0: {},
        1: {
          name: "Canada",
          title:
            "Learn more about the admission policies of some top-ranked Canadian institutions.",
          contentList: [
            "We have won plenty of honors awarded by universities – The World’s Best Agency;",
            "We have established the cooperation with a wide range of high-quality, practical and academic public colleges in Canada;",
            "We provide partners with high-class and comprehensive college-undergraduate-graduate programs",
            "We are widely recognized and trusted by many comprehensive and primarily undergraduate universities in Canada",
            "We have established long-term, deep and strategic cooperation with many medical doctoral universities in Canada",
            "We have been invited to participate in the process of formulating global strategies and optimizing programs to promote Canadian Universities for many times;",
            "The Canadian-University team consists of leading experts with more than 10 years of relevant experience;",
          ],
        },
        2: {
          name: "U.S.A",
          title:
            "Learn more about the admission policies of some top-ranked American institutions",
          contentList: [
            "Official Test Site of the American iTep",
            "Official Registration Center of UCLA Summer Sessions",
            "The Office of the University of Delaware in China",
            "The Office of San Diego State University in China",
            "Certified Member of AIRC",
            "The China Organizer of Liden Tours",
            "Our Major Partners: Shorelight, INTO, Navitas, Kaplan, Study Group, etc..",
            "Partner for recruiting international students to partial graduate programs at Northeastern University, Case Western Reserve University, Yeshiva University, UC Riverside, etc..",
            "Partner with many other Top 100 American Universities, i.e. University of Southern California, UCLA, Syracuse University, Fordham University, Virginia Tech, Clark University, University of Connecticut, SUNY Binghamton, University of  San Diego, Drexel University, Miami University-Oxford, University of San Francisco, etc.. ",
          ],
        },
        3: {
          name: "UK&EU",
          title:
            "Learn more about the admission policies of some top-ranked British institutions",
          contentList: [
            "Work visa is available after graduation for 1-2years;",
            "Large investment of government education, high quality education",
            "Pure native English speaking country in Europe, good language environment;",
            "With many world top 100 institutions like University College Dublin.",
            "One of the most safest and happiest countries.",
            "Close relationship between China and Ireland, passing rate for students visa is higher than 90%",
          ],
        },
        4: {
          name: "Australia",
          title:
            "Learn more about the admission policies of some top-ranked Australian institutions",
          contentList: [
            "We have a professional team of qualified PIER counselors with over 8-year experience in the international education consulting industry. Our services include but not limited to student recruitment, branding campaigns and marketing promotions such as live seminars, information sessions & educational fairs, target campaigns in Chinese school network, and landing services, etc. to produce cost effective results and lead to a pipeline of student applications and enrollments.",
            "We represent major universities, colleges and schools in Australia and our team has thorough understandings of the ESOS Act, visa processing, academic programs and the culture in Australia. Our experienced specialists handle student applications with cares, integrity and professionalism. Throughout our ethical and professional services, we assist our partner institutions to build strong and unique profiles, increase their marketing efficiency and student recruitment in China and we assist our clients to achieve their life goals by achieving the best results of student applications. "
            // "Full side institutions materials, top expert team which has more than 8-year working experience in the same area.",
            // "Free application service for all institutions in Australia, free application fee for most institutions.",
            // "E-application, no need for paper application documents, average admission in 1-4 weeks",
            // "Free services for E-visa submission, air pick-up and accommodation contact.",
            // "Marketing activities supports available( schools interviews, schools demo meetings, internet demo meetings and trainings)",
            // "Leading commission and reward policies in the area.",
            // "Students get medical insurance, can do part-time jobs during their study period, can get a work visa after graduation for 2-5 years.",
            // "Many choices after graduation, students can stay in the city, immigrate or come back China for working.",
            // "World-class educational material and facility",
          ],
        },
        5: {
          name: "NZL&Asia",
          title:
            "Learn more about the admission policies of some top-ranked NZL&Asia institutions",
          contentList: [
            "Focusing on studying in New Zealand for more than 10 years.",
            "Average working experience in this area is more than 6 years.",
            "All school could provide pre evaluation service, can make sure the admission rate.",
            "Cooperation establishment with many private colleges in Singapore and Malaysia",
            "Entrusted schools agent for eight colleges, more than a dozen of science institutions and hundreds of primary and middle schools in New Zealand.",
          ],
        },
      },
    };
  },
  // onShow(){
  //   console.log(2)
  // },
  watch: {
    $route(to, from) {
      this.stateChange(to.query.id);
    },
    activeIndex(newVal) {
      if (newVal == 0) {
        this.$nextTick(() => {
          this.$refs.refProjectriht.forEach((item) => {
            item.init();
          });
          this.$refs.refProjectleft.forEach((item) => {
            item.init();
          });
        });
      } else {
        this.getList();
      }
    },
  },
  methods: {
    toDetail(data) {
      this.$router.push({ name: "projectDetail", params: { ...data } });
    },
    pcstateChange(i) {
      this.activeIndex = i;
      console.log("pcstateChange", i)
      if(i !== 0){
        this.$router.push({ path: '/CooperativeProjects', query: { id: i } });
      } else {
        this.$router.push({ path: '/CooperativeProjects' });
      }
      this.page = 1;
      this.list = [];

      this.onLoad();
      //wp 2023.7 修改链接为跳转页面
      // window.location.href = '/CooperativeProjects?id=' + i;
      /*this.page = 1;
      this.getList();*/
      //wp end 2023.7 修改链接为跳转页面
    },
    prev(e) {
      this.page = e;
      this.getList();
    },
    next(e) {
      this.page = e;
      this.getList();
    },
    current(e) {
      this.page = e;
      this.getList();
    },
    stateChange(i) {
      console.log("stateChange", i)
      if (i == this.activeIndex) {
        return;
      }
      this.page = 1;
      this.activeIndex = i;
      this.list = [];

      this.onLoad();
    },
    getList() {
      let _that = this;
      projectList({
        country: _that.activeIndex,
        page: _that.page,
        rows: 12,
      }).then((res) => {
        _that.pcList = res.rows;
        _that.total = Number(res.total);
      });
    },
    stateName(x) {
      return this.navList[x.country];
    },
    tonav() {
      window.scrollTo(730, 730);
    },
    falseShow() {
      this.detailShow = false;
    },
    trueShow(data) {
      this.$refs.refdetail.setData(data);
      this.detailShow = true;
    },
    getStateList() {
      projectProjectByCountry().then((res) => {
        if (window.webType()) {
          this.proList = res;
        } else {
          this.proList.push(...res);
        }
      });
    },
    onLoad() {
      projectList({
        country: this.activeIndex,
        page: this.page,
        rows: 12,
      }).then((res) => {
        this.page = this.page + 1;
        this.list.push(...res.rows);
        this.total = Number(res.total);
        this.loading = false;
        if (this.list.length >= this.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      });
    },
    top() {
      window.scrollTo(0, 0);
    },
    toDeatil() {
      this.$router.push({ name: "detail" });
    },
  },
  mounted() {
    window.webType() ? (this.activeIndex = 1) : (this.activeIndex = 0);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.query.id) {
      this.activeIndex = this.$route.query.id;
    }
    this.getStateList();
    this.getList();
    if (window.webType()) {
      window.addEventListener("scroll", function () {
        let dom = document.getElementsByClassName("gq-news-top")[0];
        if (dom) {
          if (window.scrollY >= 600) {
            dom.style.display = "flex";
          } else {
            dom.style.display = "none";
          }
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.gq-project-top-phone-box {
  display: none;
}
.gq-news-top {
  display: none;
}
.gq-project-phone-list {
  display: none;
}
.gq-project-list {
  display: block;
  .gq-project-list-box {
    width: 1081px;
    padding-top: 112px;
    display: flex;
    margin: 0 auto;
    // justify-content: center;
    flex-wrap: wrap;
    .gq-project-list-item {
      width: 338px;
      height: 471px;
      margin-left: 10px;
      margin-right: 10px;
      img {
        width: 338px;
        height: 338px;
      }
      .gq-project-list-item-txt {
        font-size: 18px;
        font-weight: 300;
        color: #333333;
        padding-top: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
    }
  }
}
.gq-project-page-box {
  display: flex;
  justify-content: center;
  margin-top: 46px;
  margin-bottom: 67px;
}
/deep/ .btn-prev {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .btn-next {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .number {
  width: 38px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background: #ffffff;
}
/deep/ .active {
  background: linear-gradient(225deg, #62818e 0%, #81ccd9 100%);
}
.gq-project-top-box {
  height: 685px;
  display: block;
  position: relative;
  .gq-project-state-box {
    background: rgba(101, 137, 151, 0.8);
    width: 1080px;
    height: 684px;
    padding-left: 180px;
    position: absolute;
    left: 0px;
    top: 0px;
    .gq-project-state-title {
      font-size: 105px;
      font-weight: bold;
      color: #fff;
      margin-top: 28px;
    }
    .gq-project-state-ul {
      margin-top: 28px;
      li {
        display: flex;
        margin-top: 12px;
        .gq-project-fk {
          width: 15px;
          height: 15px;
          background: #fff;
          display: inline-block;
          margin-right: 23px;
          display: block;
          margin-top: 6px;
        }
        .gq-project-span-txt {
          width: 940px;
          color: #fff;
          line-height: 28px;
          font-size: 18px;
          display: block;
          text-align: justify;
        }
      }
    }
  }
  .project-top-img {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 991px;
    height: 555px;
  }
  .gq-project-top-background {
    width: 685px;
    height: 100%;
    background: #658a97;
    position: absolute;
    left: 0;
    top: 0;
  }

  .gq-project-top-txt {
    top: 278px;
    position: absolute;
    right: 50px;
    font-size: 135px;
    font-family: Helvetica;
    font-weight: bold;
    color: #fff;
    line-height: 120px;
    font-style: oblique;
  }
  .gq-project-top-bottom-state-jiantou {
    width: 134px;
    height: 134px;
    // background: #658a97;
    cursor: pointer;
    position: absolute;
    right: 232px;
    bottom: 0px;
    z-index: 999;
    text-align: center;
    img {
      width: 76px;
      height: 104px;
      transform: rotate(-90deg);
      margin-top: 20px;
    }
  }
  .gq-project-top-bottom-jiantou {
    cursor: pointer;
    width: 134px;
    height: 134px;
    background: #658a97;
    position: absolute;
    right: 180px;
    bottom: 66px;
    z-index: 999;
    text-align: center;
    img {
      width: 100px;
      height: 100px;
      transform: rotate(90deg);
      margin-top: 20px;
    }
  }
  .gq-project-nav-ul {
    font-size: 23px;
    font-weight: normal;
    color: #000000;
    width: 750px;
    position: absolute;
    left: 180px;
    bottom: 32px;
    display: flex;
    justify-content: space-between;
    li {
      padding-bottom: 10px;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      cursor: pointer;
    }
    .li-active {
      border-bottom: 2px solid #7dc2cf;
    }
  }
}
@media only screen and (max-width: 768px) {
  .gq-project-bottom {
    display: none;
  }
  .gq-project-top-box {
    display: none;
  }
  .gq-project-list {
    display: none;
  }
  .gq-project-top-phone-box {
    display: block;
    .news-top-img-box {
      width: 10rem;
      height: 6.16rem;
      position: relative;
      overflow: hidden;
      img {
        width: 10rem;
        height: 6.16rem;
      }
    }
    .gq-project-phone-ul {
      margin-top: 0.37rem;
      padding-left: 0.27rem;
      // padding-right: 0.27rem;
      display: flex;
      justify-content: space-between;
      width: 9.44rem;
      font-size: 0.37rem;
      font-weight: 300;
      height: 1.2rem;
      text-align: center;
      line-height: 0.8rem;

      li {
        width: 1.87rem;
        height: 0.8rem;
      }
      .li-active {
        background: linear-gradient(180deg, #62818e 0%, #81ccd9 100%);
        border-radius: 3px;
        color: #fff;
      }
    }
  }
  .gq-project-phone-list {
    display: block;
    border-top: 1px solid #d8d8d8;
    // justify-content: space-between;
    padding-left: 0.27rem;
    padding-right: 0.27rem;
    width: 9.44rem;
    overflow: hidden;
    .gq-project-phone-item {
      width: 4.48rem;
      height: 4.48rem;
      position: relative;
      padding-top: 0.4rem;
      img {
        width: 4.48rem;
        height: 4.48rem;
      }
      .gq-project-phone-item-title {
        position: absolute;
        bottom: 0px;
        width: 4.48rem;
        padding-top: 0.16rem;
        padding-bottom: 0.16rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.4);
        font-size: 0.32rem;
        font-weight: bold;
        line-height: 0.8rem;
        color: #ffffff;
      }
    }
  }
  .gq-news-top {
    display: none;
    justify-content: center;
    // flex-direction: column;
    z-index: 99;
    width: 1.33rem;
    height: 1.33rem;
    border-radius: 50%;
    background: #6f9fac;
    text-align: center;
    position: fixed;
    bottom: 0.53rem;
    right: 0.53rem;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.16);
    font-size: 0.24rem;
    font-weight: 400;
    color: #ffffff;
    .gq-news-top-img-box {
      margin-top: 0.2rem;
      width: 0.54rem;
      height: 0.54rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
      }
    }
  }
  /deep/.van-list {
    display: flex;
    justify-content: space-between;
    width: 9.44rem;
    flex-wrap: wrap;
  }
  /deep/ .van-list__finished-text {
    width: 10rem;
  }
}
</style>